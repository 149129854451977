.ag-header-icon.ag-header-cell-menu-button {
  opacity: 1 !important;
}
.ag-icon-filter::before {
  content: unset !important;
}
.ag-icon-menu::before {
  content: url('/public/filter.svg') !important;
}
.ag-header-cell-filtered .ag-icon-menu::before {
  content: url('/public/ActiveFilter.svg') !important;
}
.desc-wrap {
  width: 89%;
  overflow: hidden;
}
.modal-wrap {
  display: flex;
  width: 500px;
  height: 500px;
  background-color: white;
}
.orders-icon {
  background: url('/public/orders.svg') no-repeat center;
}
.inventory-icon {
  background: url('/public/inventory.svg') no-repeat center;
}
.support-ticketing-icon {
  background: url('/public/support_tickets.svg') no-repeat center;
}
.orders-icon,
.inventory-icon,
.support-ticketing-icon {
  background-size: 75% 75%;
  padding: 0 15px;
}
.info-icon {
  background: url('/public/info_icon.svg') no-repeat center;
}
.orders-page,
.inventory-page,
.support-page {
  height: 105px;
  width: 130px;
}
.orders-page {
  background: url('/public/orders.svg') no-repeat top;
}
.inventory-page {
  background: url('/public/inventory.svg') no-repeat top;
}
.support-page {
  background: url('/public/support_tickets.svg') no-repeat top;
}
.my-team-icon {
  background: url('/public/myTeam.svg') no-repeat top;
}
.nav-triangle {
  background: url('/public/triangle.svg') no-repeat center;
  margin: 9px 10px 0 13px;
  width: 6px;
}
.orders-icon-sidebar {
  background: url('/public/orders_icon.svg') no-repeat top;
}
.inventory-icon-sidebar {
  background: url('/public/inventory_icon.svg') no-repeat top;
}
.tickets-icon-sidebar {
  background: url('/public/tickets_icon.svg') no-repeat top;
}
.commissions-icon {
  background: url('/public/commissions_icon.svg') no-repeat top;
}
.home-icon-sidebar {
  background: url('/public/home_icon.svg') no-repeat top;
}
.logout-icon-sidebar {
  background: url('/public/logout_icon.svg') no-repeat top;
}
.arrow-sidebar {
  background: url('/public/arrow.svg') no-repeat top;
  background-position: center;
}
.home-icon-sidebar,
.orders-icon-sidebar,
.inventory-icon-sidebar,
.tickets-icon-sidebar,
.my-team-icon,
.commissions-icon,
.logout-icon-sidebar {
  height: 25px;
  width: 30px;
  background-size: contain;
  flex: none;
}
.expand-button {
  width: 20px;
  height: 20px;
  padding: 0px;
  position: absolute;
  top: 47px;
  left: 100%;
  background-color: white;
  border: 0px;
  border-radius: 50%;
  box-shadow:
    rgb(9 30 66 / 8%) 0px 0px 0px 1px,
    rgb(9 30 66 / 8%) 0px 2px 4px 1px;
  color: black;
  cursor: pointer;
  opacity: 1;
  outline: 0px;
  transform: translateX(-50%);
  transition:
    background-color 100ms linear 0s,
    color 100ms linear 0s,
    opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
}
.expand-button.reverse-button {
  transform: translateX(-50%) rotate(-180deg);
}
.sidebar-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sidebar .expand-button {
  display: none;
}
.sidebar:hover .expand-button {
  display: initial;
}
.box-check,
.box-check-hidden {
  background: url('/public/check.svg') no-repeat left;
  background-size: 16px;
  width: 25px;
  float: left;
  height: 14px;
}
.box-check-hidden {
  background: #fff;
}
.filter-button {
  background: url('/public/filter.svg') no-repeat center;
  background-position: left;
}
.menu-customize-button {
  background: url('/public/customize.svg') no-repeat center;
}
.menu-sort-button {
  background: url('/public/sort.svg') no-repeat center;
}
.menu-filter-button {
  background: url('/public/filter.svg') no-repeat center;
}
.dropdown-triangle {
  background: url('/public/triangle.svg') no-repeat center;
}
.menu-group-button {
  background: url('/public/more.svg') no-repeat center;
}
.collapse-icon {
  background: url('/public/contract.svg') no-repeat center;
  width: 18px;
}
.expand-icon {
  background: url('/public/expand.svg') no-repeat center;
}
.impersonate-icon {
  background: url('/public/glasses.svg') no-repeat center;
  width: 15px;
  height: 17px;
}

.columns-icon {
  background: url('/public/Columns.svg') no-repeat center;
  width: 16px;
  height: 16px;
}

.density-icon {
  background: url('/public/Density.svg') no-repeat center;
  width: 16px;
  height: 16px;
}

.export-icon {
  background: url('/public/Export.svg') no-repeat center;
  width: 16px;
  height: 16px;
}

.question-icon {
  background: url('/public/Question.svg') no-repeat center;
  width: 32px;
  height: 32px;
}

.filter-icon {
  background: url('/public/filter.svg') no-repeat center;
  width: 25px;
  height: 25px;
}

.refresh {
  background: url('/public/refresh.svg') no-repeat center;
  background-size: 35px;
  width: 25px;
  height: 25px;
}

.newDoc {
  background: url('/public/newDoc.svg') no-repeat center;
  background-size: 35px;
  width: 25px;
  height: 25px;
}

.info-icon {
  background: url('/public/Info.svg') no-repeat center;
  width: 20px;
  height: 25px;
}

.edit-icon {
  background: url('/public/edit.svg') no-repeat center;
  width: 25px;
  height: 25px;
}

.disabled-icon {
  background: url('/public/disabled.svg') no-repeat center;
  width: 25px;
  height: 25px;
}

.enabled-icon {
  background: url('/public/enabled.svg') no-repeat center;
  width: 25px;
  height: 25px;
}

.external {
  background: url('/public/external.svg') no-repeat center;
  background-size: 35px;
  width: 25px;
  height: 25px;
}

.settings-icon {
  background: url('/public/Settings.svg') no-repeat center;
  width: 21px;
  height: 21px;
}

.notifications-icon {
  background: url('/public/Notifications.svg') no-repeat center;
  width: 21px;
  height: 21px;
}

.has-notifications-icon {
  background: url('/public/has_notifications.svg') no-repeat center;
  width: 14px;
  height: 14px;
}

.no-notifications-icon {
  background: url('/public/no_notifications.svg') no-repeat center;
  width: 12px;
  height: 12px;
}

.notifications-dot {
  background: url('/public/notification_dot.svg') no-repeat center;
  width: 10px;
  height: 10px;
}

.notifications-read-dot {
  background: url('/public/notification_read_dot.svg') no-repeat center;
  width: 10px;
  height: 10px;
}

.account-icon {
  background: url('/public/Account.svg') no-repeat center;
  width: 21px;
  height: 21px;
}
.contact-icon {
  background: url('/public/Contact.svg') no-repeat center;
  width: 21px;
  height: 21px;
}
.logout-small-icon {
  background: url('/public/LogOutSmall.svg') no-repeat center;
  width: 21px;
  height: 21px;
}

.more-icon {
  background: url('/public/more.svg') no-repeat center;
  cursor: pointer;
  height: 15px;
  width: 17px;
}
select {
  appearance: none;
  background-image: url('/public/select.svg');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
}
.close-button {
  background: url('/public/close.svg') no-repeat center;
}
.drag-button {
  background: url('/public/move.svg') no-repeat center;
}
.calendar-button {
  background: url('/public/calendar.svg') no-repeat center;
}
.details-button {
  background: url('/public/details.svg') no-repeat center;
}
.circlemark {
  background: url('/public/circlemark.svg') no-repeat center;
  background-size: 80%;
  display: inline-block;
  position: relative;
}
.plus-circle {
  background: url('/public/plus_circle.svg') no-repeat center;
  background-color: #2e01a4;
  border-radius: 15px;
  height: 13px;
  width: 13px;
}
.grey-checkmark {
  background: url('/public/GrayCheck.svg') no-repeat center;
}
.green-checkmark {
  background: url('/public/GreenCheck.svg') no-repeat center;
  width: 18px;
  height: 18px;
}
.indigo-checkmark {
  background: url('/public/indigoCheckmark.svg') no-repeat center;
  width: 18px;
  height: 18px;
}
.alert-circle {
  background: url('/public/AlertCircle.svg') no-repeat center;
  width: 25px;
  height: 25px;
}
.key {
  background: url('/public/key.svg') no-repeat center;
  width: 20px;
  height: 15px;
}
.cancel-button {
  background: url('/public/cancel.svg') no-repeat center;
}
.red-cancel {
  background: url('/public/cancel-white.svg') no-repeat center;
  background-color: red;
  color: white;
  border-radius: 8px;
}
.retry-button {
  background: url('/public/retry.svg') no-repeat center;
  height: 20px;
  width: 20px;
}
.trash-button {
  background: url('/public/trash.svg') no-repeat center;
  height: 14px;
  width: 11px;
}
.minus-button {
  background: url('/public/minus.svg') no-repeat center;
}
.plus-button {
  background: url('/public/plus.svg') no-repeat center;
}
.pin {
  background: url('/public/pin.svg') no-repeat center;
}

.loading-page {
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  z-index: 1000;
  position: fixed;
  user-select: none;
}

.loading-section {
  text-align: center;
  width: 100%;
  height: 100%;
  user-select: none;
  font-size: 65px;
}

.loading-page .saving {
  font-size: 40px;
  position: fixed;
  width: auto;
  z-index: 10;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -50px;
}

.saving span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  color: #2e01a4;
  font-family: initial;
  font-size: 80px;
}
.loading-dot-2 {
  animation-delay: 0.2s;
}
.loading-dot-3 {
  animation-delay: 0.4s;
}
.loading-dot-4 {
  animation-delay: 0.6s;
}
.loading-dot-5 {
  animation-delay: 0.8s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 791px;
  padding: 5px;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.popup-content .popup-body {
  flex-grow: 1;
  max-height: 90%;
  overflow-y: auto;
}

.invalid-feedback {
  color: red;
  font-size: 12px;
}

.invalid-feedback:not(.m-0) {
  margin-left: 32px;
}

.ticket-modal-content {
  width: 358px !important;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.2) !important;
}

.custom-tooltip-content {
  margin: auto;
  padding: 10px;
}
.custom-tooltip-arrow {
  color: #f7f7f7;
}
[role='tooltip'].custom-tooltip-content {
  width: 300px;
  font-size: 12px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

[data-popup='tooltip'].custom-tooltip-overlay {
  background: transparent;
}

[data-popup='tooltip'].tooltip-add-view-overlay,
[data-popup='tooltip'].tooltip-custom-views-overlay {
  background: transparent !important;
}

[role='tooltip'].tooltip-custom-views-content {
  box-shadow: inherit;
}

[role='tooltip'].tooltip-add-view-content {
  overflow: inherit;
}

.dot {
  border-radius: 50%;
  height: 10px;
  padding-right: 10px;
  width: 10px;
}
.recharts-pie-sector {
  cursor: pointer;
}
.cluster div {
  color: white !important;
}

.gm-style-iw button.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

.MuiDataGrid-filler--pinnedLeft.css-fjdwss {
  border-right: none;
}

.recharts-bar-rectangle {
  cursor: pointer;
}

.impersonate-banner {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: #2e01a4;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
}

.impersonate-banner-text {
  color: white;
  margin: 0;
}

.impersonate-exit-button {
  margin-left: 16px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.impersonate-exit-button:hover {
  background-color: white;
  color: #2e01a4;
}

.widget-container .recharts-wrapper,
.widget-container .recharts-wrapper svg {
  max-width: 100%;
}

.with-rectangle:after {
  bottom: 0;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

#outside_inventory_form {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
}

.rjsf > div > div:has(.config-error) {
  visibility: hidden;
}

.rjsf > div:has(.config-error)::before {
  content: 'Error generating inventory form';
  color: red;
}

#outside_inventory_update_form {
  flex-direction: column;
  width: 100%;
}

.ql-container.ql-snow .ql-editor,
.ql-editor {
  a {
    color: #2e01a4;
    text-decoration: underline;
  }
  h1 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
  }
  pre.ql-syntax {
    color: #454545;
    background: #d3d3d3;
  }
}

.ql-container {
  .ql-tooltip {
    left: 5px !important;
  }
}

.ql-editor.pl-0 {
  padding-left: 0;
}

.mention {
  padding: 1px 3px;
  border-radius: 25px;
  background-color: #e7e7e7;
}

.ql-mention-list > .selected {
  background-color: #f7f7f7;
}

.ql-container.ql-snow.ql-disabled {
  border: none;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0.5em !important;
}

.menu {
  display: inline-flex;
  flex-direction: column;
}

.menu-item {
  cursor: pointer;
  padding: 5px;
  height: 28px;
  text-align: left;
  width: 100%;
}

.hiddenCanvasElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
}
